/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { SexMasterClass } from '@/store/sexMasterClass/types';

const namespace: string = 'sexMasterClass';

export default class SexMasterClassesVideoChoicePage extends Vue {
	@State('sexMasterClass') sexMasterClass: SexMasterClass | undefined;
}
